<script lang="ts" setup>
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import type { Ref } from 'vue';
import type { OfferMedium } from '~/types/NewTypes/CatalogNew';

const props = defineProps<{
  offers: OfferMedium[];
  title?: string | null;
  noPadding?: boolean;
  recommendationsBlockId?: string;
}>();

const existingOffers = computed(() => {
  return props.offers.filter((offer) => offer.id);
});

const isShowNavigation = computed(() => existingOffers.value.length > 4);

onMounted(() => {
  sliderInitialization();
});

const swiperInstance: Ref<any> = ref();
const swiperBlock = ref();
const swiperPaginationBlock = ref();

function sliderInitialization() {
  swiperInstance.value = new Swiper(swiperBlock.value, {
    breakpoints: {
      640: {
        slidesOffsetBefore: props.noPadding ? 0 : 12,
        spaceBetween: 24,
      },
      1024: {
        slidesOffsetBefore: 0,
        spaceBetween: 24,
      },
    },
    modules: [Pagination, Navigation],
    navigation: {
      nextEl: '.swiper-button--next',
      prevEl: '.swiper-button--prev',
    },
    pagination: {
      clickable: true,
      el: swiperPaginationBlock.value,
      type: 'bullets',
    },
    slidesOffsetBefore: props.noPadding ? 0 : 12,
    slidesPerView: 'auto',
    spaceBetween: 12,
  });
}

const { sendProductEventToDataLayer } = useGtmModule();

function handleProductClick(offer: OfferMedium, index: number) {
  sendProductEventToDataLayer('select_item', offer, index);
  const route = useRoute();

  if (props.recommendationsBlockId) {
    route.query.recommended_by = 'dynamic';
    route.query.recommended_code = props.recommendationsBlockId;
  }

  navigateTo({
    path: offer.uri,
    query: route.query,
  });
}
</script>

<template>
  <section
    :class="{ '!px-0': noPadding }"
    class="product-cards-gallery"
  >
    <div
      v-if="title"
      :class="{ 'px-3 lg:px-0': !noPadding }"
      v-html="title"
    />

    <div
      ref="swiperBlock"
      class="swiper product-cards-gallery__container"
    >
      <div class="swiper-wrapper">
        <ProductCard
          v-for="(offer, key) in existingOffers"
          :key="offer.id"
          :offer="offer"
          :recommendations-block-id="recommendationsBlockId"
          class="product-cards-gallery__item swiper-slide"
          role="button"
          @click="handleProductClick(offer, key)"
        />
      </div>

      <div
        v-if="isShowNavigation"
        class="swiper-navigation"
      >
        <div class="swiper-button swiper-button--prev">
          <SvgoChevronLeft
            class="text-2xl"
            filled
          />
        </div>

        <div class="swiper-button swiper-button--next">
          <SvgoChevronRight
            class="text-2xl"
            filled
          />
        </div>
      </div>

      <div
        ref="swiperPaginationBlock"
        class="swiper-pagination"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.product-cards-gallery {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @include screen-lg {
    @include inner-content;
  }

  &__container {
    width: 100%;
  }

  &__item {
    width: 100%;
    max-width: 46vw;

    @include screen-sm {
      width: 270px;
    }
  }
}
</style>
